body {
  overflow: hidden !important;
}

.App {
  padding: 10px 20px 0px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
      background-color: white !important;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.calendar-container {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  transition: all 0.3s ease;
  overflow: hidden;
}

.event-details {
  z-index: 9999;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-color: white;
  overflow-y: auto;
  transition: width 0.3s ease;
  display: none;
  box-shadow: 2px 2px 12px 12px rgba(36, 36, 36, 0.205);
}

.event-details.active {
  display: block;
  width: 90%;
}

.event-details h2 {
  margin-top: 0;
  padding: 20px;
}

.close-button {
  display: block;
  margin: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.close-button:hover {
  background-color: #0056b3;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 20px;
}

.card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  position: relative;
}

.modal-content .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}